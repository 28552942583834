@import '../../../styles/variaveis.scss';

.contentSection{
    margin-top: 5% !important;
}

.sideBarOpen{
    margin-left: calc(100% - 80%);
}

.sideBarClose{
    margin-left: calc(100% - 90%);
}

.close{
    width: calc(100% + 15%);
}