@import '../../styles/variaveis.scss';

.mainSection {
    background: linear-gradient(to bottom, $grayPrimary, $orangeSecondary);
    height: 100vh;
    padding-top: 5%;
    padding-left: 55%;
    font-family: 'PlusJakartaSans-Light' !important;
    position: relative;

    @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        justify-content: center;
    }
    
    @media (min-width: 768px) and (max-width: 991px) {
        padding-left: 10%;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        padding-left: 15%;
    }
}

.loginContainer {
    background: $loginGradient;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os itens */
    justify-content: center;
    width: 100%;
    max-width: 550px;

    height: auto; /* Ajusta a altura automaticamente */
    min-height: 720px; /* Define a altura mínima */
    margin: auto;
    border-radius: 1rem;
    padding: 2rem; /* Adiciona um padding interno */
    position: relative; /* Para o posicionamento do ícone de visibilidade da senha */
}

img {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    max-width: 300px;
}

.inputGroup {
    width: 100%;
}

.floatingLabel {
    position: relative;
    margin-bottom: 1rem;

    input {
        width: 100%;
        padding: 0.95rem;
        font-size: 1rem;
        border: 1px solid $grayPrimary;
        border-radius: .5rem;
        color: $orangePrimary !important;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: $orangePrimary;
            box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
            outline: none;
        }

        &::placeholder {
            color: $orangePrimary !important;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        font-size: 1rem;
        color: $orangePrimary;
        pointer-events: none;
        transition: 0.3s ease all;
        transform: translateY(-50%);
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        top: -0.5rem;
        left: 0.75rem;
        font-size: 0.75rem;
        color: $orangePrimary;
        background: $whitePrimary;
        padding: 0 0.25rem;
    }
}

.icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    cursor: pointer;
    color: $fontColor;
}

.customCheckbox {
    color: $orangeSecondary;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.spanLabel {
    font-size: 1rem;
    color: $orangePrimary;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
.spanAccess{
    color: $orangePrimary;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.buttonPolicy {
    color: $brownPrimary !important;
    font-size: .8rem;
    background: none !important;
    border: none !important;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.mascotSection {
    position: absolute;
    top: 20%; /* Ajustado para centralizar verticalmente */
    left: 10%; /* Ajustado para mover a seção para a direita */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%; /* Ajustado para aumentar a área do mascote */
    height: auto;
    z-index: 10; /* Garante que a seção do mascote fique sobre o fundo */
    
    @media (max-width: 1199px) {
        display: none; /* Esconde a seção em telas menores que 1200px */
    }

    .imageMascot {
        width: 120%; /* Ajusta a largura da imagem para preencher a seção */
        height: auto; /* Mantém a proporção da altura em relação à largura */
        max-width: 800px; /* Define um tamanho máximo para a imagem, ajuste conforme necessário */
    }
}

@media(min-width: 300px) and (max-width: 600px){
    .mainSection{
        height: 130vh;
    }

    .loginContainer{
        max-width: 22rem;
    }
}