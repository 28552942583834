@import '../../../../styles/variaveis.scss';

.product-list-container {
  background: $whitePrimary;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  padding: 1rem;
  font-family: 'PlusJakartaSans-Light', sans-serif;
  margin-top: 2rem;
  color: $orangePrimary !important;

  @media (max-width: 480px){
    width: 70%;
  }
  @media (max-width: 764px){
    width: 110%;
  }
}

.product-checkbox {
  .form-check-input {
    accent-color: $orangePrimary !important;
    color: $orangePrimary !important;
  }

  .form-check-label {
    color: $orangePrimary !important;
    font-family: 'PlusJakartaSans-Light', sans-serif;
  }
}
