@import '../../../../../../styles/variaveis.scss';

.messageBank{
    text-wrap: nowrap;
    color: $brownPrimary;
    font-size: 1.1rem;
    font-family: 'PlusJakartaSans-Bold';

    @media (max-width: 768px){
        font-size: 1rem;
    }
    @media (max-width: 480px){
        font-size: .9rem;
    }
}