@import '../../../../../styles/variaveis.scss';

.tableContainer {
  overflow: hidden;
  border-radius: 12px;
}

.scrollableTable {
  max-height: 24.2rem; // Altura máxima para 6 registros
  overflow-y: auto; // Habilita a rolagem vertical
  position: relative;

  // Personalização da barra de rolagem
  &::-webkit-scrollbar {
    width: 20px !important;
    background-color: $orangePrimary !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $orangePrimary !important;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $yellowSecondary !important; // Cor da trilha da barra de rolagem
  }
}

.tableStyle {
  font-family: 'PlusJakartaSans-Light' !important;
  border-collapse: collapse;
  width: 100%;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #D9D9D9 !important; // Cor de fundo para o cabeçalho
}

th {
  background-color: #D9D9D9 !important;
  color: $brownPrimary !important;
  padding: 1.2rem !important;
  font-family: 'PlusJakartaSans-Bold' !important;
  text-align: center;

  &:first-child {
    border-top-left-radius: 12px !important;
  }

  &:last-child {
    border-top-right-radius: 12px !important;
  }
}

td {
  text-align: center;
  padding: 1.5rem !important;
  background-color: $yellowOrange !important;
  border-right: 2px solid #D9D9D9;
  border-bottom: 10px solid #D9D9D9 !important;
}
