@import '../../../styles/variaveis.scss';

.buttonLogin {
    background-color: $orangeSecondary;
    color: $whitePrimary;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: .5rem;
    padding: .6rem 1.5rem;
    cursor: pointer;
    padding-left: 150px;
    padding-right: 150px;
    margin-bottom: 1rem;

    &:hover {
        background-color: $orangePrimary;
        transition: background .5s;
    }

    &:disabled {
        background-color: $orangeSecondary;
        color: $whitePrimary; 
        cursor: not-allowed;
        opacity: 0.65;
    }
}
