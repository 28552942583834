@import '../../styles/variaveis.scss';

.contentSection{
    margin-left: 17% !important;

    @media(max-width: 764px){
        margin-left: 7% !important;
    }
}

.layoutContainer {
    margin-left: -3%;
    display: flex;
    justify-content: left;
    height: calc(100vh - 300px); 
    padding: 20px;
    transition: .5s all;

    @media(max-width: 764px){
        flex-direction: column;
    }
}

.layoutContainerClose{
    margin-left: -10% !important;
    transition: .5s all;
}

.verticalLine {
    width: 2px;
    background-color: $orangeSecondary;
    margin: 0 20px;
}

.sidebar {
    flex-basis: 400px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin-top: 5%;
    min-width: 300px;
}

.notificationCard{
    border: none;
    box-shadow: $boxShadow;
    text-align: left;
}

.notificationsContent{
    font-family: 'PlusJakartaSans-Medium';
    font-size: 1.1rem;
    width: 90%;
    margin-left: 5%;
}

.notificationDate{
    background: $orangeSecondary !important;
    font-size: .7rem;
}

.checkboxContainer {
    position: absolute;
    top: 1rem;
    right: -3rem;
    pointer-events: none; 
}

.buttonCheckbox{
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.3rem;
    color: $fontColor;
    font-family: 'PlusJakartaSans-Medium';

    span{
        margin-left: 7px;
    }
}

.icon{
    border: 2px solid #ccc;
    color: $whitePrimary;
    font-size: 1rem;
}

.iconActive{
    border: none;
    color: $fontColor;
    font-size: 1.5rem;
}

.buttonsContainer{
    gap: .5rem;
}

@media(max-width:1450px){
    .contentSection{
        margin-left: 7% !important;
    }
}