@import '../../../styles/variaveis.scss';


.mainSection{
    margin-top: 20%;
    max-width: 40rem;
    margin-bottom: 3rem;
}

.icon{
    color: $orangePrimary;
    font-size: 25px;
    cursor: pointer;
}

.cardsContainer{
    width: 60rem;
    gap: 2rem;
}

@media(max-width:932px){
    .mainSection{
        margin-left: 5% !important;
    }
    .cardsContainer{
        max-width: 75%;
    }
}