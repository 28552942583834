//paleta
$orangePrimary: #153B7F;
$orangeSecondary: #487DC0;
$orangeDanger: #153B7F;
$yellowPrimary: #487DC0;
$yellowSecondary: #F2B705;
$yellowDataItem: #F2B505;
$yellowOrange: #153B7F;

$whitePrimary: #fff;

//background colors
$grayPrimary: #F2F2F2;
$beigePrimary:#FEEECC;
$beigeSecondary:#FDDE9A;
$orangeBackground: #F8F1DD;

$brownPrimary: #1D2F7D;
$brownSecondary: #153B7F;

//GRADIENTES
$backgroundGradient: linear-gradient(135deg, $orangePrimary, #153B7F);
$underlineGradient: linear-gradient(to right, #F2B705, #F2B705); 
$sideBarGradient: linear-gradient(to bottom, #487DC0 10%, #153B7F 60%);
$cardGradient: linear-gradient(to bottom, #487DC0, #153B7F); 
$yellowGradient: linear-gradient(to bottom, #FDDE9A 10%, #F2B705 70%); 
$loginGradient: linear-gradient(to bottom, $whitePrimary, rgba($beigePrimary, 0.8) 80%);

$border: 1px solid #ccc;
$boxShadow:  0px 4px 4px 0px #00000040;

//fontes 
$fontColor: #153B7F;

@font-face {
    font-family: 'PlusJakartaSans-Light';
    src: url(../assets/fonts/PlusJakartaSans-Light.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Medium';
    src: url(../assets/fonts/PlusJakartaSans-Medium.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url(../assets/fonts/PlusJakartaSans-Bold.ttf);
}
