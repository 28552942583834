@import '../../../../styles/variaveis.scss';


.modalGeral{
    border-radius: 25px !important;
}
.modalHeader{
    font-family: 'PlusJakartaSans-Bold';
    background-color: #F27605;
    color: white;
}
.modalBody{
    font-family: 'PlusJakartaSans-Light';
    background-color: $yellowSecondary;
    color: $brownSecondary;
}
.imageModal{
    width: 63%;
}
.rowLero{
    margin-top: 13%;
}