@import '../../../../../styles/variaveis.scss';

.mainContainer{
    margin-left: -5%;
    box-shadow: $boxShadow;
    border-radius: .5rem;
    padding: 5px 10px;
}

.buttonFooter{
    margin-left: -10%;

    @media (max-width: 768px){
        margin-right: 5%;
    }
}
