@import '../../../styles/variaveis.scss';

.buttonGrayUltimate {
    background-color: #D9D9D9;
    color: $brownPrimary;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: .5rem;
    padding: .6rem 1.5rem;
    font-family: 'PlusJakartaSans-Light';
    cursor: pointer;
    margin-bottom: 1rem;
    transition: all .5s;

    &:hover {
        background-color: #D9D9D9;
        color: $brownPrimary; 
        transition: all .5s;
        transform: scale(1.1);
    }

    &:disabled {
        background-color: #D9D9D9;
        color: $brownPrimary; 
        cursor: not-allowed;
        opacity: 0.65;
    }
    &:focus{
        background-color: #D9D9D9 !important;
        color: $brownPrimary !important; 
    }
}
