@import '../../../../styles/variaveis.scss';

.mainContainer {
    padding: 2rem;
    font-family: 'PlusJakartaSans-Light';
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full height to prevent overflow */
    overflow: hidden; /* Prevent horizontal scrolling */
}

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid $orangeSecondary;
    padding-right: 1rem;
    position: relative;
    flex-shrink: 0; /* Prevent shrinking */
}

.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.profilePicture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $orangePrimary;
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.iconUser{
    color: $whitePrimary;
}

.profileName {
    font-size: 1.2rem;
    font-weight: bold;
}

.navButtons {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.navButton {
    margin-bottom: 0.5rem;
    width: 100%;
    border: none; /* Remove bordas */
}

.navButton:hover {
    background-color: $orangePrimary; /* Define cor de fundo do hover */
    color: white; /* Define cor do texto do hover */
}

.active {
    background-color: $orangePrimary;
    color: white;
}


.verticalLine {
    height: 100%;
    border-left: 1px solid $orangeSecondary;
    flex-shrink: 0; /* Prevent shrinking */
}

.contentContainer {
    padding-left: 1rem;
    flex-grow: 1; /* Allow to grow and fill available space */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .sidebar {
        border-right: none; /* Remove border on small screens */
        padding-right: 0; /* Remove padding on small screens */
        flex-direction: row; /* Align sidebar and line vertically in a row */
        justify-content: center; /* Center align items */
    }

    .profileContainer {
        flex-direction: column; /* Alinha o ícone e o nome em coluna */
        align-items: flex-start; /* Alinha ao início */
    }
    .profilePicture {
        margin-bottom: 0.5rem;
    }

    .profileName {
        margin-bottom: 1rem;
    }

    .verticalLine {
        display: none; /* Hide vertical line on small screens */
    }

    .contentContainer {
        padding-left: 0; /* Remove padding on small screens */
    }
}
