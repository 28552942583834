@import '../../../styles/variaveis.scss';

.buttonDelete {
    background-color: rgb(240, 26, 26);
    color: $whitePrimary;
    font-size: 1rem;
    font-family: 'PlusJakartaSans-Light';
    font-weight: bold;
    border: none;
    border-radius: .5rem;
    padding: .6rem 1.5rem;
    cursor: pointer;
    margin-bottom: 1rem;

    &:hover {
        background-color: rgb(240, 26, 26);
        transition: background .5s;
    }

    &:disabled {
        background-color: rgb(240, 26, 26);
        color: $whitePrimary; 
        cursor: not-allowed;
        opacity: 0.65;
    }
}
