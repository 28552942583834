/* General container styles */
.container {
    display: flex;
    flex-direction: row;
}

/* Navbar section styles */
.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}

/* Content section styles */
.contentSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.logoSection{
    margin-top: 12%;
    margin-bottom: -14%;
}
.sideBarOpen {
    margin-left: 20%;
}

.sideBarClose {
    margin-left: 15% !important;
}

@media (max-width: 800px) {
    .navbarSection {
        width: 100%;
    }

    .contentSection {
        margin-left: 0;
        width: 100%;
    }
}

@media (max-width: 570px) {
    .navbarSection {
        width: 100%;
    }

    .contentSection {
        margin-left: 0;
        width: 100%;
    }
}
