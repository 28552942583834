@import '../../../../../styles/variaveis.scss';

.container {
    display: flex;
    flex-direction: row;
}
.titleSection, .subTitleSection{
    margin-left: 20% !important;
}
.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}

.logoSection{
    margin-top: 12%;
    margin-bottom: -11%;
}
.contentSection {
    flex: 1;
    margin-left: calc(100% - 80%) !important;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.balanceConatiner{
    margin-right: 2rem;
}

.sideBarClose{
    margin-left: calc(100% - 85%) !important;
}

@media (max-width: 1450px) {
    .contentSection{
        margin-left: 5% !important;
    }
    .titlesSection{
        margin-left: -12%;
    }
}