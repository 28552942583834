@import '../../../../styles/variaveis.scss';

.container {
    display: flex;
    flex-direction: row;
}
.titleSection, .subTitleSection{
    margin-left: 20% !important;
}
.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}

.contentSection {
    flex: 1;
    margin-left: 23% !important;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.sideBarOpen {
    margin-left: 20%;
}

.sideBarClose {
    margin-left: 0;
}

.balanceConatiner{
    margin-right: 2rem;
}
