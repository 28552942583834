@import '../../../../styles/variaveis.scss';

/* General container styles */
.container {
    display: flex;
    flex-direction: row;
}

/* Navbar section styles */
.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}

/* Content section styles */
.contentSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.logoSection{
  margin-top: 12%;
  margin-bottom: -12%;
}
.sideBarOpen {
    margin-left: 20%;
}

.sideBarClose {
    margin-left: 15% !important;
}

@media (max-width: 800px) {
    .navbarSection {
        width: 100%;
    }

    .contentSection {
        margin-left: 0;
        width: 100%;
    }
}

@media (max-width: 570px) {
    .navbarSection {
        width: 100%;
    }

    .contentSection {
        margin-left: 0;
        width: 100%;
    }
}

.tableContainer {
  overflow: hidden;
  border-radius: 12px;
}

.scrollableTable {
  max-height: 34rem;
  overflow-y: auto; // Enables vertical scrolling
  position: relative;

  &::-webkit-scrollbar {
    width: 20px !important;
    background-color: $orangePrimary !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $orangePrimary !important;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $yellowSecondary !important;
  }
}

.tableStyle {
  font-family: 'PlusJakartaSans-Light' !important;
  border-collapse: collapse;
  width: 100%;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #D9D9D9 !important;
}

th {
  background-color: #D9D9D9 !important;
  color: $brownPrimary !important;
  padding: 1.2rem !important;
  font-family: 'PlusJakartaSans-Bold' !important;
  text-align: center;

  &:first-child {
    border-top-left-radius: 12px !important;
  }

  &:last-child {
    border-top-right-radius: 12px !important;
  }
}

td {
  text-align: center;
  color: white !important;
  padding: 1.5rem !important;
  background-color: $yellowOrange !important;
  border-right: 2px solid #D9D9D9;
  border-bottom: 10px solid #D9D9D9 !important;
}

.navLink {
  color: $orangePrimary; // Cor do texto padrão para os pills não ativos
  &:hover {
    color: $yellowSecondary; // Cor do texto quando em hover (opcional)
  }
}

.navLinkActive {
  background-color: $orangePrimary !important; // Cor de fundo desejada para o pill ativo
  color: #fff !important; // Cor do texto para o pill ativo
}

@media (max-width: 1450px) {
  .contentSection{
      margin-left: 5% !important;
  }
}