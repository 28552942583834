@import "../../../../styles/variaveis.scss";

.container {
  display: flex;
  flex-direction: row;
}
.titleSection {
  margin-top: 5% !important;
}
.titleSection,
.subTitleSection {
  margin-left: 18% !important;
}
.navbarSection {
  width: 20%;
  transition: width 0.3s ease;
}
.logoSection{
  margin-top: 12%;
  margin-bottom: -14%;
}
.contentSection {
  flex: 1;
  margin-top: 5% !important;
  margin-left: 23% !important;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;

  @media (max-width: 480px) {
    margin-left: 10% !important;
  }
  @media (max-width: 764px) {
    margin-left: 10% !important;
  }
}

.sideBarOpen {
  margin-left: 20%;
}

.sideBarClose {
  margin-left: 0;
}

.floatingLabel {
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    padding: 0.95rem;
    font-size: 1rem;
    border: 1px solid $grayPrimary;
    border-radius: 0.5rem;
    color: $orangePrimary !important;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
      border-color: $orangePrimary;
      box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
      outline: none;
    }

    &::placeholder {
      color: transparent; /* Oculta o placeholder quando o label está flutuando */
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    font-size: 1rem;
    color: $orangePrimary;
    pointer-events: none;
    transition: 0.3s ease all;
    transform: translateY(-50%);
    background: $whitePrimary; /* Garante que o fundo não fique transparente quando flutua */
    padding: 0 0.25rem;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    top: -0.5rem;
    left: 0.75rem;
    font-size: 0.75rem;
    color: $orangePrimary;
  }
}

.fileInput {
  display: none;
}

.fileUploadButton {
  display: inline-block;
  cursor: pointer;
}

@media (max-width: 1450px) {
    .contentSection{
        margin-left: 5% !important;
    }
    .titlesSection{
        margin-left: -12%;
    }
}