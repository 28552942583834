@import '../../../styles/variaveis.scss';

.inputText {
  border-radius: 21px;
  font-family: 'PlusJakartaSans-Light';
  border: 2px solid $yellowPrimary;
  padding-right: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 3px 4px 1px #00000040;
  
  &::placeholder {
    color: $fontColor;
  }

  &:focus {
    border-color: $fontColor;
    outline: none;
    box-shadow: 0 0 0 3px rgba($fontColor, 0.5);
  }

  position: relative;
}

.inputWrapper {
  position: relative;
  display: inline-block;

  .searchButton {
    position: absolute;
    right: 10px;
    top: 30%;
    transform: translateY(-50%);
    background-color: $yellowSecondary;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 7px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
}
