@import '../../styles/variaveis.scss';

.toast {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1050;
    min-width: 200px;
    color: $orangeDanger;
    font-family: 'PlusJakartaSans-Light';
    background-color: $whitePrimary;
    box-shadow: $boxShadow;
    border-radius: .5rem; 
    padding: 1rem; 
    border: 2px solid $orangePrimary; 
  }

  .span{
    font-family: 'PlusJakartaSans-Medium';
    color: $brownPrimary;
    font-size: 15px;
    margin-left: 1rem;
  }

  .icon{
    color: $orangeSecondary;
    font-size: 20px;
  }