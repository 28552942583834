@import '../../styles/variaveis.scss';

.mainSection {
    background: linear-gradient(to bottom, $grayPrimary, $orangeSecondary);
    height: 100vh;
    padding-top: 5%;
    font-family: 'PlusJakartaSans-Light' !important;
    position: relative;

    @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        justify-content: center;
    }
    
    @media (min-width: 768px) and (max-width: 991px) {
        padding-left: 10%;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        padding-left: 15%;
    }
}

.loginContainer {
    background: $loginGradient;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    width: 100%;
    max-width: 550px;

    height: auto;
    min-height: 720px; 
    margin: auto;
    border-radius: 1rem;
    padding: 2rem; 
    position: relative;
}

img{
    margin-top: -10% !important;
    margin-bottom: 15%;
}

.inputGroup {
    width: 100%;
}

.floatingLabel {
    position: relative;
    margin-bottom: 1rem;

    input {
        width: 100%;
        padding: 0.95rem;
        font-size: 1rem;
        border: 1px solid $grayPrimary;
        border-radius: .5rem;
        color: $orangePrimary !important;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: $orangePrimary;
            box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
            outline: none;
        }

        &::placeholder {
            color: $orangePrimary !important;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        font-size: 1rem;
        color: $orangePrimary;
        pointer-events: none;
        transition: 0.3s ease all;
        transform: translateY(-50%);
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        top: -0.5rem;
        left: 0.75rem;
        font-size: 0.75rem;
        color: $orangePrimary;
        background: $whitePrimary;
        padding: 0 0.25rem;
    }
}