@import '../../styles/variaveis.scss';

.loginContainer {
    background: $loginGradient;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    width: 100%;
    max-width: 550px;
    height: auto; 
    min-height: 720px; 
    margin: auto;
    border-radius: 1rem;
    padding: 2rem; 
    position: relative;
}

img {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    max-width: 300px;
}

.stepper{
    width: 100%;
}

.steperContent{
    width: 90%;
}
.inputGroup {
    width: 100%;
}

.floatingLabel {
    position: relative;
    margin-bottom: 1rem;

    input {
        width: 100%;
        padding: 0.95rem;
        font-size: 1rem;
        border: 1px solid $grayPrimary;
        border-radius: .5rem;
        color: $orangePrimary !important;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: $orangePrimary;
            box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
            outline: none;
        }

        &::placeholder {
            color: $orangePrimary !important;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        font-size: 1rem;
        color: $orangePrimary;
        pointer-events: none;
        transition: 0.3s ease all;
        transform: translateY(-50%);
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        top: -0.5rem;
        left: 0.75rem;
        font-size: 0.75rem;
        color: $orangePrimary;
        background: $whitePrimary;
        padding: 0 0.25rem;
    }
}

.codeInputs{
    width: 3.5rem;  
    height: 4rem;
    font-size: 1.5rem;
    border: 1px solid $grayPrimary;
    border-radius: .5rem;
    color: $orangePrimary !important;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
        border-color: $orangePrimary;
        box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
        outline: none;
    }
}