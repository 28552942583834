@import '../../styles/variaveis.scss';

.balanceContainer {
  background: none;
  border: none;
  padding: 10px 0;
  font-family: 'PlusJakartaSans-Medium';
}

.label {
  color: $orangePrimary;
  font-size: 1.2rem;
  margin-right: 10px;
}

.balance {
  font-size: 1.2rem;
  margin-right: 10px;
  color: $orangePrimary;
}

.icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: $orangePrimary;
  transition: all 0.3s;
}

.icon:hover {
  color: $orangePrimary;
}
  
.dropdownIcon{
  color: $whitePrimary;
  font-size: 1.25rem;
  margin-left: 1rem;
  background: none;
  border: none;
  
  &:hover, :active, :focus {
    background: none !important;
    outline: none !important;
  }
}

.dropdowfontAwesome{
  color: $orangePrimary;
}

.dropdown{
  border-radius: 15px;
  color: $fontColor;
  font-size: 17px;
  transition: .5s;
  width: 17rem;
  padding: 20px;
  cursor: pointer;
  font-family: 'PlusJakartaSans-Light';

  .dropdownItem:hover{
      background-color: $orangeBackground;
      transition: .5s;border-radius: .5rem;    
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .balanceContainer {
    padding: 10px 0;
  }

  .label,
  .balance {
    font-size: 1rem;
  }
}

@media(max-width:932px){
  .balanceContainer{
      max-width: 55%;
  }
}