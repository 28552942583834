@import '../../styles/variaveis.scss';

.sideBar{
    display: flex;
    font-family: 'PlusJakartaSans-Light';
    flex-direction: column;
    background: $sideBarGradient;
    width: 320px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    color: $grayPrimary;
    transition: transform 0.3s ease;
    z-index: 1000;
}

.closed{
    transform: translateX(-100%);
}

.open{
    transform: translateX(0);
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;

    .bars{
        margin-bottom: 8rem;
        color: $grayPrimary;
    }

    img{
        width: 80px;
        height: 95.58px;
        margin-top: 48px;
        margin-left: 90px;
        gap: 0px;
        opacity: 100 !important;
    }
}

.bars{
    cursor: pointer;
    font-size: 2rem;
    color: $orangePrimary;
}

.content{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: 3rem;
}

.navItem{
    width: Fixed (201.55px)px;
    height: Hug (18px)px;
    top: 237px;
    left: 54px;
    gap: 20px;
    opacity: 100 !important;
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    text-decoration: none;
    transition: 0.3s;    
    
    &:hover{
        transform: scale(1.1);
        transition: 0.3s;
    }
}

.navIcon{
    margin-right: .5rem;
    font-size: 1.5rem;
    color: $grayPrimary;
}

.textItem{
    font-size: 20px;
    font-weight: 700;
    line-height: 25.2px;
    text-align: left;
    color: $grayPrimary;
}

.toggleMenu{
    position: fixed;
    top: .5rem;
    left: 1.5rem;
    padding: 1rem;
    border-radius: 15px;
    cursor: pointer;
    z-index: 1100;
}

.topBar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    transition: left 0.3s ease, width 0.3s ease;
    z-index: 1001;
}

.topBar.menuOpen {
    left: 320px; 
    width: calc(100% - 320px);
}

.topBarIconsContainer{
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 3rem;
    margin-right: 1rem;
}

.topBarIcons{
    color: $orangePrimary;
    font-size: 1.7rem;
    transition: .5s;
    cursor: pointer;

    &:hover{
        transform: scale(1.2);
        transition: .5s;
    }
}

.dropdownIcon{
    color: $orangePrimary;
    font-size: 1.25rem;
}

.dropdown{
    border-radius: 15px;
    color: $fontColor;
    font-size: 17px;
    transition: .5s;
    padding: 20px;
    font-family: 'PlusJakartaSans-Light';
    
    .dropdownItem:hover{
        background-color: $orangeBackground;
        transition: .5s;border-radius: .5rem;    
    }
}

.btnClose:hover{
        transform: scale(1.2);
}

.btnLogout{
    background-color: $orangePrimary;
    border: 1px solid $orangePrimary;
    
    &:hover{
        background-color: $orangePrimary;
        border: 1px solid $orangePrimary;
        transform: scale(1.2);
    }
}

.logoutTitle{
    font-family: 'PlusJakartaSans-Bold';
    color: $orangePrimary;
}

@media (max-width: 800px) {
    .topBar {
      display: none;
    }
  
    .footerContainer {
      display: block;
    }
  
    .sideBar {
      width: 70%;
    }
  
    .toggleMenu {
      display: block;
    }
}

@media(max-width: 600px){
    .sideBar{
        max-width: 45%;
    }
}

@media(max-width: 1000px){
    .sideBar{
        max-width: calc(100% - 20%);
    }
}
  
.subMenu {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    transition: .5s all;

    .subMenuItem {
        padding: 5px 0;
        color: white;
        text-decoration: none;
        transition: .5s all;

        &:hover {
            transition: .5s all;
            text-decoration: underline;
            transform: scale(1.1);
        }
    }
}

.chevronIcon {
    margin-left: auto;
}

.barsIconSemiTransparent {
    color: rgba(128, 128, 128, 0.5);
    font-size: 2rem;
    cursor: pointer;
}



.notificationBadge {
    background-color: $orangeBackground;
    color: $orangePrimary;
    font-size: 0.9rem;
    border-radius: 50%;
    padding: 2px 8px;
    margin-left: -10px;
}

.unreadIndicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $orangeBackground;
    margin-left: 5px;
}

.link{
    color: $fontColor;
}
.userNameContainer {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 14px;
}

.userName {
    font-weight: bold;
}