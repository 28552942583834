@import '../../../../styles/variaveis.scss';

.tableContainer {
  overflow: hidden;
  border-radius: 12px;
}

.scrollableTable {
  max-height: 30rem; // Altura maior para acomodar mais linhas
  overflow-y: auto;
  position: relative;
  width: 100%;  // Garantir que a tabela ocupe toda a largura disponível
}

.tableStyle {
  font-family: 'PlusJakartaSans-Light' !important;
  border-collapse: collapse;
  width: 100%;
  font-size: 1rem;  // Tamanho de fonte ajustado para melhor legibilidade
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #D9D9D9 !important;
}

th {
  background-color: #D9D9D9 !important;
  color: $brownPrimary !important;
  padding: 1.5rem !important;
  font-family: 'PlusJakartaSans-Bold' !important;
  text-align: center;
  font-size: 1.1rem;  // Tamanho da fonte ajustado para maior destaque
}

td {
  text-align: center;
  padding: 0.8rem !important;
}

td:first-child {
  padding-left: 2rem;
  padding-right: 1rem;
}

button {
  font-family: 'PlusJakartaSans-Light' !important;
}
