@import '../../styles/variaveis.scss';

.toast {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1050;
  min-width: 200px;
  color: $orangeDanger;
  font-family: 'PlusJakartaSans-Light';
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.075); /* Sombra */
  border-radius: 0.25rem; /* Adicionar borda arredondada */
  padding: 1rem; /* Adicionar algum preenchimento */
  border: 2px solid red; /* Adicionar uma borda visível para debugging */
}

