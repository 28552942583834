@import '../../../../styles/variaveis.scss';

.container {
    display: flex;
    flex-direction: row;
}
.titleSection, .subTitleSection{
    margin-left: 20% !important;
}
.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}

.contentSection {
    flex: 1;
    margin-left: 23% !important;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.sideBarOpen {
    margin-left: 20%;
}

.sideBarClose {
    margin-left: 15% !important;
}

.balanceConatiner{
  margin-right: 2rem;
}

.floatingLabel {
    position: relative;
    margin-bottom: 1rem;

    input {
        width: 100%;
        padding: 0.95rem;
        font-size: 1rem;
        border: 1px solid $grayPrimary;
        border-radius: .5rem;
        color: $orangePrimary !important;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: $orangePrimary;
            box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
            outline: none;
        }

        &::placeholder {
            color: transparent; /* Oculta o placeholder quando o label está flutuando */
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        font-size: 1rem;
        color: $orangePrimary;
        pointer-events: none;
        transition: 0.3s ease all;
        transform: translateY(-50%);
        background: $whitePrimary; /* Garante que o fundo não fique transparente quando flutua */
        padding: 0 0.25rem;
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        top: -0.5rem;
        left: 0.75rem;
        font-size: 0.75rem;
        color: $orangePrimary;
    }
}


.reviewContainer {
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 2rem;
}

.leftSection {
  border-right: 1px solid #ccc;
  padding-right: 2rem;
}

.rightSection {
  padding-left: 2rem;
}

.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: $brownPrimary;
}

.editButton {
  background: transparent;
  border: none;
  color: $orangePrimary;
  padding: 0;
}

.dataItem {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  color: $yellowDataItem;
  font-size: 1.1rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
}
.titleDataItem{
  color: $brownPrimary !important;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
}

.itemSpan {
  display: inline-block;
  margin-right: 1rem;
  background-color: $yellowDataItem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  padding-top: 0.3rem;
  border-radius: 8px;
  padding-bottom: 0.3rem;
  color: white;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
}

.switchContainer {
  display: flex;
  align-items: center;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: $brownPrimary !important;
  font-size: 1.2rem;
  justify-content: center;

  .form-check-label {
    color: $brownPrimary !important;
    font-weight: bold;
    font-family: 'PlusJakartaSans-Bold', sans-serif;
    margin-left: 8px !important;
  }

  .form-check-input {
    width: 50px;
    height: 24px;
    background-color: $orangePrimary;
    border-color: $orangePrimary;
    cursor: pointer;
    &:checked {
      background-color: $orangePrimary !important;
      border-color: $orangePrimary !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
