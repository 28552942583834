@import '../../../../../../styles/variaveis.scss';

.cardContainer{
    margin-left: -5%;

    @media (max-width: 768px) {
        margin-left: 0;
    }
}

.card{
    border: none;
    width: 90%;
    height: 25rem;
    box-shadow: $boxShadow;

    @media (max-width: 768px) {
        width: 100%;
        height: 20rem;
    }

    @media (max-width: 480px) {
        height: 18rem;
    }
}

.imgCard{
    margin: 2rem 1rem;
    width: 85%;
    max-height: 7.5rem;
    object-fit: contain;

    @media (max-width: 768px) {
        width: 80%;
        margin: 1rem;
    }

    @media (max-width: 480px) {
        width: 75%;
        margin: 0.5rem;
    }
}

.title{
    font-size: 1.3rem;
    color: $brownPrimary;
    font-family: 'PlusJakartaSans-Bold';

    @media (max-width: 768px) {
        font-size: 1.1rem;
    }

    @media (max-width: 480px) {
        font-size: 1rem;
    }
}

.span{
    color: $brownPrimary;
    font-family: 'PlusJakartaSans-Bold';
    font-size: 1rem;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }

    @media (max-width: 480px) {
        font-size: 0.8rem;
    }
}

.text{
    color: $orangePrimary;
    font-family: 'PlusJakartaSans-Light';
    font-size: 1rem;
    border: none;

    &:focus{
        outline: none;
        box-shadow: none;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }

    @media (max-width: 480px) {
        font-size: 0.8rem;
    }
}

.buttons{
    margin: 0 .7rem;

    @media (max-width: 768px) {
        margin: 0 .5rem;
    }

    @media (max-width: 480px) {
        margin: 0 .3rem;
    }
}

.icon {
    position: absolute;
    top: calc(100% - 34%);
    right: calc(100% - 185%);
    transform: translateY(-50%);
    cursor: pointer;
    color: $fontColor;

    @media (max-width: 768px) {
        top: calc(100% - 33%);
        right: calc(100% - 180%);
    }

    @media (max-width: 480px) {
        top: calc(100% - 34%);
        right: calc(100% - 180%);
    }
}