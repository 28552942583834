@import '../../../../styles/variaveis.scss';

.customInput {
    width: 100%;
    font-size: 1rem; 
    border: 1.5px solid #ccc; 
    border-radius: 0.5rem; 
    padding: 0.75rem;
    transition: all 0.3s;

    &:focus {
        border-color: 2px solid $orangeSecondary;
        outline: none;
    }
}

.customLabel {
    font-size: 1rem;
    color: #888;
    margin-left: .5rem;
}