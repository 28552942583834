@import '../../../../../../styles/variaveis.scss';

.floatingLabel {
    position: relative;
    margin-bottom: 1rem;

    input {
        width: 100%;
        padding: 0.95rem;
        font-size: 1rem;
        border: 1px solid $grayPrimary;
        border-radius: 0.5rem;
        color: $orangePrimary !important;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: $orangePrimary;
            box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
            outline: none;
        }

        &::placeholder {
            color: $orangePrimary !important;
        }

        @media (max-width: 768px) {
            padding: 0.8rem;
            font-size: 0.9rem;
        }

        @media (max-width: 480px) {
            padding: 0.7rem;
            font-size: 0.85rem;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        font-size: 1rem;
        color: $orangePrimary;
        pointer-events: none;
        transition: 0.3s ease all;
        transform: translateY(-50%);

        @media (max-width: 768px) {
            font-size: 0.9rem;
        }

        @media (max-width: 480px) {
            font-size: 0.85rem;
        }
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        top: -0.5rem;
        left: 0.75rem;
        font-size: 0.75rem;
        color: $orangePrimary;
        background: $whitePrimary;
        padding: 0 0.25rem;

        @media (max-width: 768px) {
            font-size: 0.7rem;
        }

        @media (max-width: 480px) {
            font-size: 0.65rem;
        }
    }
}

.title {
    font-size: 1rem;
    color: $brownPrimary;
    font-family: 'PlusJakartaSans-Bold';

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }

    @media (max-width: 480px) {
        font-size: 0.75rem;
    }
}

.icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    cursor: pointer;
    color: $fontColor;

    @media (max-width: 768px) {
        right: 0.5rem;
    }

    @media (max-width: 480px) {
        right: 0.3rem;
    }
}
