.navbarSection {
    float: left;
    width: 20%;
}

.contentSection{
    max-width: 100rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    margin-left: 120px;
}

.sideBarOpen{
    transition: all .5s;
    gap: 10rem;
    margin-left: 15rem;
}

.open{
    max-width: 40rem;
}

.close{
    max-width: 50rem;
}

.sideBarClose{
    max-width: 100rem;
    transition: all 5s;
    margin-left: 10rem !important;
}


.logoSection{
    margin-top: 12%;
    margin-bottom: -14%;
}
@media(max-width: 450px){
    .contentSection, .navbarSection{
        width: 100%;
    }

    .sideBarClose{
        margin-left: 5% !important;
    }
}

@media(max-width: 650px){
    .contentSection, .navbarSection{
        width: 100%;
    }

    .sideBarClose{
        margin-left: -8rem !important;
    }
}

@media(max-width:800px){
    .sideBarClose, .logoSection{
        margin-left: -25% !important;
    }
    .navbarSection {
        float: none;
        width: 100vh;
    }
}

@media(max-width: 1180px){
    .contentSection{
        margin-left: -15% !important;
    }
    .logoSection{
        margin-left: calc(100% - 95%);
    }
}

@media(max-width: 1400px){
    .sideBarOpen{
        margin-left: 5%;
    }

    .sideBarClose{
        display: flex;
        flex-direction: column;
    }

    .close{
        width: 100vh;
        margin-left: 20%;
    }
}

@media(max-width: 1650px){
    .sideBarOpen{
        margin-left: 17rem;
    }

    .sideBarClose{
        margin-left: calc(100% - 93%) !important;
    } 
}