@import '../../../../styles/variaveis.scss';

.modalGeral{
    border-radius: .5rem !important;
}
.modalHeader{
    font-family: 'PlusJakartaSans-Bold';
    background-color: #F27605;
    color: white;
}
.modalBody{
    font-family: 'PlusJakartaSans-Light';
    background-color: $yellowSecondary;
    color: $brownSecondary;
}
.imageModal{
    margin-top: 12%;
    width: 63%;
}
.rowLero{
    margin-top: 10%;
}