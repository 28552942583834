.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  
    .page-item {
      margin: 0 5px;
      border-radius: 5px;
  
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }
  
      &.active {
        .page-link {
          background-color: #6f42c1; /* Cor de fundo da página ativa */
          border-color: #6f42c1; /* Cor da borda da página ativa */
          color: white; /* Cor do texto da página ativa */
          font-weight: bold; /* Destacar a página ativa */
        }
      }
  
      .page-link {
        padding: 8px 16px;
        background-color: #fff;
        color: #6f42c1; /* Cor padrão dos botões */
        border: 1px solid #ddd;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
  
        &:hover {
          background-color: #f1f1f1;
          border-color: #ccc;
        }
  
        &:focus {
          outline: none;
          box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);

        }
  
        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }
  