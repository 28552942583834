
@import '../../../styles/variaveis.scss';

.mainContainer{
    background-color: $whitePrimary;
    margin-top: 100px;
    width: 90%;
}

.header{
    margin-bottom: 8rem;
}

.extract{
    font-size: 16px;
    color: $fontColor;
} 

.iconCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px; 
    height: 15px; 
    border-radius: 50%; 
    border: 1px solid $fontColor; 
    font-size: 8px; 
    color: $fontColor;
}

@media(min-width: 650px){
    .mainContainer{
        width: 40rem;
    }
}
