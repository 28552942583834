@import '../../../../styles/variaveis.scss';

.container {
    display: flex;
    flex-direction: row;
}
.titleSection, .subTitleSection{
    margin-left: 20% !important;
}
.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}
.logoSection{
    margin-top: 12%;
    margin-bottom: -11%;
}
.contentSection {
    flex: 1;
    margin-left: 23% !important;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;

    @media(max-width: 480px){
        margin-left: 10% !important;
    }
    @media(max-width: 764px){
        margin-left: 15% !important;
    }
}

.sideBarOpen {
    margin-left: 20% !important;
}

.open{
    margin-left: 0 !important;
}

.close{
    margin-left: -10% !important;
}

.sideBarClose {
    margin-left: 15% !important;
}

.balanceConatiner{
    margin-right: 2rem;
}

@media (max-width: 1450px) {
    .contentSection{
        margin-left: 5% !important;
    }
    .titlesSection{
        margin-left: -15%;
    }
}