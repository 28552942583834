@import '../../.././../../styles/variaveis.scss';

.titleModal{
    color: $brownPrimary !important;
    font-family: 'PlusJakartaSans-Medium' !important;
}

.bodyModal{
    color: $orangeSecondary !important;
    font-family: 'PlusJakartaSans-Bold' !important;
}