@import '../../styles/variaveis.scss';

.underlineTitle {
    position: relative;
    font-family: 'PlusJakartaSans-Light';
    display: inline-block;
    padding-bottom: 0.5rem;
    color: $brownPrimary;

}
