@import '../../../../styles/variaveis.scss';

.modalBody{
    font-family: 'PlusJakartaSans-Medium' !important;
}

.alertTitleOportunity {
    background-color: $yellowOrange !important;
    border: 1px solid $yellowPrimary;
    color: white !important;
    opacity: 90%;
    font-size: 1rem !important;
}