@import '../../../styles/variaveis.scss';

.queueContainer {
  width: 70%;
  font-family: 'PlusJakartaSans-Light';
  margin: 10px 0;
}

.progressBar {
  width: 100%;
  background-color: $grayPrimary;
  border-radius: 25px;
  overflow: hidden;
  height: 30px;
  box-shadow: 0px 3px 4px 1px #00000040;
}

.progress {
  height: 100%;
  background-color: $orangePrimary;
  text-align: center;
  line-height: 30px;
  color: white;
  font-weight: bold;
  transition: width 0.4s ease-in-out;
}

.textInfo {
  margin-top: 5px;
  text-align: center;
  color: $fontColor;
}
